import React from 'react';

const Workshops = () => {
  return (
    <div>
      <div className="title">
        <h1>Workshops</h1><br></br>
      </div>
      <div className="text-container">
      </div>
    </div>
  );
};

export default Workshops;
